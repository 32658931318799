import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { desktopDown, mobile } from '@/app/common/style/mixins';
import { h2 } from '@/app/common/style/typography';
import { rem } from '@/app/common/style/functions';

const style404 = css`
  .content-error {
    ${desktopDown(css`
      margin-top: 0;
    `)}

    .page-title {
      ${mobile(css`
        text-align: center;
        padding-top: 98px;
      `)}
    }

    h1 {
      ${h2}
      letter-spacing: normal;
      margin-bottom: 54px;

      ${mobile(css`
        font-size: ${rem(30)};
        line-height: 42px;
        letter-spacing: 0.5px;
        margin-bottom: 30px;
      `)}
    }

    .error-body {
      margin: 0px auto;
      padding: 104px 0;
      text-align: center;

      ${mobile(css`
        padding-top: 30px;
      `)}

      .btn {
        text-transform: uppercase;
        line-height: 1.875rem;
        letter-spacing: 0.03125rem;
        padding: 10px 64px;
      }
    }
  }
`;

const styled404 = styled.main`
  ${style404}
`;

export default styled404;
