import React from 'react';
import { PageProps, Link } from 'gatsby';
import Styled404 from '@/components/styled/404Styled';

const NotFound: React.FC<PageProps> = () => (
  <Styled404>
    <div className="content content-error">
      <div className="container">
        <div className="page-title mini-title">
          <h1>404 Page not found</h1>
        </div>
        <div className="error-body">
          <h1>Sorry, nothing to display.</h1>
          <Link className="btn" to="/">
            Back To Home
          </Link>
        </div>
      </div>
    </div>
  </Styled404>
);

export default NotFound;
